/**
 * @generated SignedSource<<6d038d6437e566a852872cdb392fb988>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "partnerId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "type"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v5 = [
  (v2/*: any*/),
  (v3/*: any*/),
  (v4/*: any*/)
],
v6 = {
  "kind": "Literal",
  "name": "first",
  "value": 1
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bodyExcerpt",
  "storageKey": null
},
v8 = [
  {
    "alias": "partners",
    "args": [
      {
        "kind": "Literal",
        "name": "condition",
        "value": {
          "type": "TRAVEL_AGENCY"
        }
      }
    ],
    "concreteType": "PartnersConnection",
    "kind": "LinkedField",
    "name": "allPartners",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PartnersEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Partner",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": (v5/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": "allPartners(condition:{\"type\":\"TRAVEL_AGENCY\"})"
  },
  {
    "alias": "conversations",
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "participantPartnerId",
            "variableName": "partnerId"
          },
          {
            "kind": "Variable",
            "name": "type",
            "variableName": "type"
          }
        ],
        "kind": "ObjectValue",
        "name": "condition"
      },
      {
        "kind": "Literal",
        "name": "first",
        "value": 64
      }
    ],
    "concreteType": "ConversationsConnection",
    "kind": "LinkedField",
    "name": "allConversations",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalCount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ConversationsEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Conversation",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "extras",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "language",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "shortId",
                "storageKey": null
              },
              {
                "alias": "participants",
                "args": [
                  (v6/*: any*/)
                ],
                "concreteType": "ParticipantsConnection",
                "kind": "LinkedField",
                "name": "participantsByConversationId",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ParticipantsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": "participant",
                        "args": null,
                        "concreteType": "Participant",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": "partner",
                            "args": null,
                            "concreteType": "Partner",
                            "kind": "LinkedField",
                            "name": "partnerByPartnerId",
                            "plural": false,
                            "selections": (v5/*: any*/),
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "participantsByConversationId(first:1)"
              },
              {
                "alias": "messages",
                "args": [
                  (v6/*: any*/),
                  {
                    "kind": "Literal",
                    "name": "orderBy",
                    "value": "CREATED_AT_DESC"
                  }
                ],
                "concreteType": "MessagesConnection",
                "kind": "LinkedField",
                "name": "messagesByConversationId",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MessagesEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": "message",
                        "args": null,
                        "concreteType": "Message",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "createdAt",
                            "storageKey": null
                          },
                          (v7/*: any*/),
                          {
                            "alias": "parts",
                            "args": null,
                            "concreteType": "PartsConnection",
                            "kind": "LinkedField",
                            "name": "partsByMessageId",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "PartsEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": "part",
                                    "args": null,
                                    "concreteType": "Part",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v2/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "mimeType",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "order",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "body",
                                        "storageKey": null
                                      },
                                      (v7/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": "contact",
                            "args": null,
                            "concreteType": "Contact",
                            "kind": "LinkedField",
                            "name": "contactByContactId",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v3/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "firstName",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "lastName",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "email",
                                "storageKey": null
                              },
                              (v4/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "messagesByConversationId(first:1,orderBy:\"CREATED_AT_DESC\")"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ConversationsQuery",
    "selections": (v8/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ConversationsQuery",
    "selections": (v8/*: any*/)
  },
  "params": {
    "cacheID": "e35f3208871b5b7a79fefa98139b2a61",
    "id": null,
    "metadata": {},
    "name": "ConversationsQuery",
    "operationKind": "query",
    "text": "query ConversationsQuery(\n  $type: ConversationType\n  $partnerId: Uuid\n) {\n  partners: allPartners(condition: {type: TRAVEL_AGENCY}) {\n    edges {\n      node {\n        id\n        name\n        type\n      }\n    }\n  }\n  conversations: allConversations(first: 64, condition: {type: $type, participantPartnerId: $partnerId}) {\n    totalCount\n    edges {\n      node {\n        id\n        type\n        title\n        extras\n        language\n        status\n        shortId\n        participants: participantsByConversationId(first: 1) {\n          edges {\n            participant: node {\n              id\n              partner: partnerByPartnerId {\n                id\n                name\n                type\n              }\n            }\n          }\n        }\n        messages: messagesByConversationId(first: 1, orderBy: CREATED_AT_DESC) {\n          edges {\n            message: node {\n              id\n              createdAt\n              bodyExcerpt\n              parts: partsByMessageId {\n                edges {\n                  part: node {\n                    id\n                    mimeType\n                    order\n                    body\n                    bodyExcerpt\n                  }\n                }\n              }\n              contact: contactByContactId {\n                id\n                name\n                firstName\n                lastName\n                email\n                type\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

node.hash = "f0f621d6b848d96df24178dd19cf361f";

module.exports = node;
