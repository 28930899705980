import { sortBy, truncate } from 'lodash'
import React, { useCallback, useState } from 'react'
import { QueryRenderer } from 'react-relay'
import { useSearchParams } from 'react-router-dom'

import environment from '../../Environment'
import { Error, Loader } from '../../components'
import Config from '../../config'
import { CONVERSATIONS_QUERY } from './Conversations.graphql'

const config = Config(process.env.REACT_APP_ENV)

export const getNameFromUserlikeObject = ({ firstName, lastName }) => [firstName, lastName].filter(Boolean).join(' ') || undefined

export const getUserName = (obj, fallbackName) => {
  if (!obj) return fallbackName

  const { name, firstName, lastName, email, phone } = obj

  return getNameFromUserlikeObject({ firstName, lastName }) || name || email || phone || fallbackName
}

const ConversationTypes = ['CHAT', 'SMS', 'EMAIL', 'EVENT', 'REMINDER', 'BROADCAST', 'ASSISTED']
const ConversationStatuses = ['STARTED', 'INTERACTED', 'COMPLETED', 'UNCOMPLETED']

const Sentiment = ({ value, className }) => {
  const sentiment = value.toLowerCase()

  switch (sentiment) {
    case 'positive':
      return <span className={`badge bg-success ${className}`}>POSITIVE</span>
    case 'neutral':
      return <span className={`badge bg-warning ${className}`}>NEUTRAL</span>
    case 'negative':
      return <span className={`badge bg-danger ${className}`}>NEGATIVE</span>
    default:
      return null
  }
}

const Conversations = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [selectedType, setType] = useState(searchParams.get('type'))
  const [selectedPartnerId, setSelectedPartner] = useState(searchParams.get('partnerId'))
  const [selectedStatus, setStatus] = useState(searchParams.get('status')?.split(',').filter(Boolean) || [])

  const handlePartnerChange = useCallback(
    (partnerId) => {
      setSelectedPartner(partnerId)
      if (partnerId) {
        setSearchParams({ ...Object.fromEntries(searchParams), partnerId })
      } else {
        const newParams = new URLSearchParams(searchParams)
        newParams.delete('partnerId')
        setSearchParams(newParams)
      }
    },
    [searchParams, setSearchParams]
  )

  const handleTypeChange = useCallback(
    (type) => {
      setType(type)
      if (type) {
        setSearchParams({ ...Object.fromEntries(searchParams), type })
      } else {
        const newParams = new URLSearchParams(searchParams)
        newParams.delete('type')
        setSearchParams(newParams)
      }
    },
    [searchParams, setSearchParams]
  )

  const handleStatusToggle = (status) => {
    const newSelectedStatuses = selectedStatus.includes(status) ? selectedStatus.filter((s) => s !== status) : [...selectedStatus, status]

    setStatus(newSelectedStatuses)

    // Update URL params
    if (newSelectedStatuses.length > 0) {
      setSearchParams({
        ...Object.fromEntries(searchParams),
        status: newSelectedStatuses.join(',')
      })
    } else {
      const newParams = new URLSearchParams(searchParams)
      newParams.delete('status')
      setSearchParams(newParams)
    }
  }

  const renderFilterPartners = useCallback(
    (partners) => {
      return (
        <div className='dropdown'>
          <button
            type='button'
            className='btn btn-secondary dropdown-toggle'
            id='dropdownPartnersButton'
            aria-haspopup='true'
            aria-expanded='false'
            data-toggle='dropdown'
          >
            <i className='fe fe-filter mr-2'></i>
            {selectedPartnerId ? partners.find((p) => p.id === selectedPartnerId)?.name || 'All' : 'Partners'}
          </button>
          <ul
            className='dropdown-menu'
            aria-labelledby='dropdownPartnerFilter'
            style={{
              maxHeight: '400px',
              overflowY: 'auto',
              width: '100%'
            }}
          >
            <li>
              <button type='button' className='dropdown-item' onClick={() => handlePartnerChange(null)}>
                All
              </button>
            </li>
            {partners.map((p) => (
              <li key={p.id}>
                <button type='button' className='dropdown-item' onClick={() => handlePartnerChange(p.id)}>
                  {p.name}
                </button>
              </li>
            ))}
          </ul>
        </div>
      )
    },
    [selectedPartnerId, handlePartnerChange]
  )

  const renderFilterType = useCallback(() => {
    return (
      <div className='dropdown ml-2'>
        <button type='button' className='btn btn-secondary dropdown-toggle' data-toggle='dropdown'>
          <i className='fe fe-filter mr-2'></i>
          {selectedType ?? 'Type'}
        </button>
        <ul className='dropdown-menu' aria-labelledby='dropdownFilterButton'>
          <li>
            <button type='button' className='dropdown-item' onClick={() => handleTypeChange(null)}>
              All
            </button>
          </li>
          {ConversationTypes.map((type) => (
            <li key={type}>
              <button type='button' className='dropdown-item' onClick={() => handleTypeChange(type)}>
                {type}
              </button>
            </li>
          ))}
        </ul>
      </div>
    )
  }, [selectedType, handleTypeChange])

  const renderFilterStatus = useCallback(() => {
    return (
      <div className='dropdown ml-2'>
        <button type='button' className='btn btn-secondary dropdown-toggle' data-toggle='dropdown'>
          <i className='fe fe-filter mr-2'></i>
          {selectedStatus.length > 0 ? selectedStatus.map(s => s.substring(0, 3)).join(', ') : 'Status'}
        </button>
        <ul className='dropdown-menu' aria-labelledby='dropdownFilterButton'>
          {ConversationStatuses.map((status) => (
            <li key={status}>
              <div className='dropdown-item'>
                <label className='d-flex align-items-center mb-0'>
                  <input type='checkbox' className='mr-2' checked={selectedStatus.includes(status)} onChange={() => handleStatusToggle(status)} />
                  {status}
                </label>
              </div>
            </li>
          ))}
        </ul>
      </div>
    )
  }, [searchParams, setSearchParams, selectedStatus])

  return (
    <QueryRenderer
      environment={environment}
      query={CONVERSATIONS_QUERY}
      variables={{
        type: selectedType,
        partnerId: selectedPartnerId
      }}
      render={({ error, props }) => {
        if (error) return <Error error={error} />
        if (!props) return <Loader />

        let conversations = props.conversations?.edges?.map((ed) => ed.node) ?? []

        const totalCount = props.conversations?.totalCount
        const currentCount = conversations.length

        const partners = props.partners?.edges?.map((ed) => ed.node) ?? []

        // Calculate statistics
        const completedCount = conversations.filter((c) => c.status === 'COMPLETED').length
        const uncompletedCount = conversations.filter((c) => c.status === 'UNCOMPLETED').length
        const assistedCount = conversations.filter((c) => c.type === 'ASSISTED').length

        // // filter out started assisted conversations
        // if (selectedType === 'ASSISTED') {
        //   conversations = conversations.filter((c) => c.status !== 'STARTED')
        // }

        if (selectedStatus.length > 0) {
          conversations = conversations.filter((c) => selectedStatus.includes(c.status))
        }

        return (
          <div className='my-3 my-md-5'>
            <div className='container'>
              <div className='row row-cards'>
                <div className='col-lg-12'>
                  <div className='card'>
                    <div className='card-header'>
                      <div>
                        <h3 className='card-title'>{`Conversations (${currentCount} / ${totalCount})`}</h3>
                        <div className='text-muted small mt-1'>
                          <span className='mr-3'>
                            <i className='fe fe-check-circle text-success mr-1'></i>
                            {completedCount} completed
                          </span>
                          <span className='mr-3'>
                            <i className='fe fe-alert-circle text-warning mr-1'></i>
                            {uncompletedCount} uncompleted
                          </span>
                          <span>
                            <i className='fe fe-help-circle text-info mr-1'></i>
                            {assistedCount} assisted
                          </span>
                        </div>
                      </div>
                      <div className='ml-auto'>
                        {renderFilterPartners(partners)}
                        {renderFilterType()}
                        {renderFilterStatus()}
                      </div>
                    </div>
                    <div className='table-responsive'>
                      <table className='table card-table table-striped table-vcenter'>
                        <thead>
                          <tr>
                            <th className='d-none d-lg-table-cell'>Last Message</th>
                            <th className='d-none d-lg-table-cell'>Sent</th>
                            <th className='d-none d-lg-table-cell'>Type</th>
                            {!selectedPartnerId && <th className='d-none d-lg-table-cell'>Partner</th>}
                          </tr>
                        </thead>
                        <tbody>
                          {conversations.map((conversation) => {
                            const partner = conversation.participants?.edges?.find((e) => e.participant.partner)?.participant.partner
                            let lastMessage
                            let sender = 'Anonymous'
                            if (conversation.messages?.edges?.length > 0) {
                              lastMessage = conversation.messages.edges[0].message
                              sender = getUserName(lastMessage?.contact, 'Anonymous')
                            }

                            const url = `${config.conversationUrl}/c/${conversation.shortId}`
                            return (
                              <tr
                                key={conversation.shortId || conversation.id}
                                style={{
                                  opacity: conversation.type === 'ASSISTED' && conversation.status === 'STARTED' ? 0.5 : 1
                                }}
                              >
                                <td>
                                  <span className='badge bg-azure-lt'>{sender}</span>

                                  {['COMPLETED', 'UNCOMPLETED'].includes(conversation.status) && (
                                    <span className={`ml-1 badge ${conversation.status === 'COMPLETED' ? 'bg-green-lt' : 'bg-orange-lt'}`}>
                                      {conversation.status}
                                    </span>
                                  )}

                                  {conversation.extras?.assisted?.sentiment && <Sentiment value={conversation.extras?.assisted?.sentiment} className='ml-1' />}

                                  <div className='d-flex align-items-center'>
                                    <a target='_blank' href={url} rel='noreferrer' title={conversation.extras?.analytics?.userAgent}>
                                      {conversation.title || 'Untitled'}
                                    </a>
                                  </div>
                                  <small>
                                    {truncate(
                                      lastMessage?.bodyExcerpt ||
                                        sortBy(
                                          (lastMessage?.parts?.edges || []).filter(({ part }) =>
                                            ['text/plain', 'text/markdown', 'text/html'].includes(part.mimeType)
                                          ),
                                          'order'
                                        )[0]?.part?.body,
                                      {
                                        length: 128
                                      }
                                    )}
                                  </small>
                                </td>
                                <td>{lastMessage?.createdAt}</td>
                                <td className='d-none d-lg-table-cell'>{conversation.type}</td>
                                {!selectedPartnerId && (
                                  <td className='d-none d-lg-table-cell'>
                                    <a href={`partners/${partner?.id}/edit`}>{partner?.name}</a>
                                  </td>
                                )}
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }}
    />
  )
}

export { Conversations }
