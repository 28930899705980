import graphql from 'babel-plugin-relay/macro'

const CONVERSATIONS_QUERY = graphql`
  query ConversationsQuery($type: ConversationType, $partnerId: Uuid) {
    partners: allPartners(condition: { type: TRAVEL_AGENCY }) {
      edges {
        node {
          id
          name
          type
        }
      }
    }

    conversations: allConversations(
      first: 64
      condition: { type: $type, participantPartnerId: $partnerId }
    ) {
      totalCount
      edges {
        node {
          id
          type
          title
          extras
          language
          status
          shortId
          participants: participantsByConversationId(first: 1) {
            edges {
              participant: node {
                id
                partner: partnerByPartnerId {
                  id
                  name
                  type
                }
              }
            }
          }
          messages: messagesByConversationId(
            first: 1
            orderBy: CREATED_AT_DESC
          ) {
            edges {
              message: node {
                id
                createdAt
                bodyExcerpt
                parts: partsByMessageId {
                  edges {
                    part: node {
                      id
                      mimeType
                      order
                      body
                      bodyExcerpt
                    }
                  }
                }
                contact: contactByContactId {
                  id
                  name
                  firstName
                  lastName
                  email
                  type
                }
              }
            }
          }
        }
      }
    }
  }
`

export { CONVERSATIONS_QUERY }
